import logo from './Waalbocht_logo_white.svg';
import instalogo from './instagram-logo.png';
import untappdlogo from './untappd-logo-solid.png';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <br></br>
      </header>
      <body className="App-body">
        <p className='App-body-title'>
        Craft Beer uit Nijmegen
        </p>
        <p>
        <a href="https://untappd.com/waalbocht" target="_blank"><img className="Social-logo" src={untappdlogo} /></a>
        <a href="https://instagram.com/waalbocht" target="_blank"><img className="Social-logo" src={instalogo} /></a>
        </p>
      </body>
      <footer className="App-footer">
        <img src={logo} className="App-logo" alt="logo" />
      </footer>
    </div>
  );
}

export default App;
